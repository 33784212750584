import Image from "../media/image";
import PropTypes from "prop-types";

const CardEmployee = ({
  coursePerson,
  smallVersion,
  conferenceRole,
  lightCard,
}) => {
  return (
    <div
      className={`
    ${
      smallVersion
        ? "c-card-employee c-card-employee--padding-smallVersion u-text--white"
        : "c-card-employee c-card-employee--padding u-text--white"
    }
    ${lightCard ? "c-card-employee--light u-text--black" : " "}
    `}
    >
      <div className="c-card-employee__head">
        {coursePerson.image && (
          <Image
            image={coursePerson.image}
            alt={"Portrett av " + coursePerson.title}
            interceptBuilder={builder => builder.width(140).height(180)}
            className="c-card-employee__image"
          />
        )}
        <div className="c-card-employee__credentials">
          {conferenceRole && (
            <p className="c-card-employee__role">{conferenceRole}</p>
          )}
          <h3>{coursePerson.title}</h3>
          {!conferenceRole && <p>{coursePerson.workPosition}</p>}

          <p>
            E-post:{" "}
            <a
              className="c-card-employee__email"
              href={"mailto:" + coursePerson.email}
            >
              {coursePerson.email} <br />
            </a>
          </p>

          {coursePerson.phoneNumber && (
            <p>
              Mobil:{" "}
              <a
                className="c-card-employee__phone"
                href={"tel:" + coursePerson.phoneNumber}
              >
                {coursePerson.phoneNumber}
              </a>
            </p>
          )}
        </div>
      </div>
      {coursePerson.keywords && !smallVersion && (
        <p className="c-card-employee__body">
          {coursePerson.keywords.reduce(
            (accumulator, currentValue) => accumulator + " • " + currentValue
          )}
        </p>
      )}
    </div>
  );
};

CardEmployee.propTypes = {
  coursePerson: PropTypes.object,
  conferenceRole: PropTypes.string,
  smallVersion: PropTypes.bool,
  lightCard: PropTypes.bool,
};

export default CardEmployee;
