import CourseInfo from "components/course/course.info";
import RelevantCourseLink from "components/course/relevant.course.link";
import SelectorProgramLocation from "components/course/selector.program.location";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import Menu from "components/layout/menu";
import PageMeta from "components/layout/pageMeta";
import PortableText from "components/portableText/portableText";
import { client } from "helpers/sanity";
import { globalQueries, portableTextQuery } from "helpers/sanityQueries";
import { GetStaticProps } from "next";
import { PreviewRequirements } from "../preview";
import { ArrangementDatoType } from "components/course/relevant.courses";

const ConferencePage = props => {
  const {
    settings,
    doc,
    doc: {
      title = "Ingen tittel",
      lede,
      signUpUrl,
      courseDate,
      endDate,
      closeBookingAt,
      recordingAvailable,
      portableText,
      price,
      suitableFor,
      keywords,
      coursePerson,
      adminPerson,
      program,
      location,
      courseLocation,
      relevantCourses,
      speakerList,
      duration,
      dateOverride,
    },
    contact,
    menu,
  } = props;

  //TODO flytte setStatus opp her, loope over alle og sette status før vi genererer linkene så man slipper  Warning: Text content did not match. Server: "" Client: "Ledig"

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      <Menu menu={menu} />
      <Header title={title} type="event" />
      <article className="o-wrapper o-grid o-grid__between o-grid__column-reverse--mobile">
        <section className="c-event-info__sidetext">
          {lede && <p className="u-fontLead">{lede}</p>}

          {portableText && (
            <div>
              <PortableText blocks={portableText} />
            </div>
          )}
        </section>
        <section className="c-event-info__container">
          <CourseInfo
            coursePerson={coursePerson}
            adminPerson={adminPerson}
            price={price}
            courseDate={courseDate}
            endDate={endDate}
            duration={duration}
            dateOverride={dateOverride}
            categories={keywords}
            courseLocation={courseLocation}
            suitableFor={suitableFor}
            signUpUrl={signUpUrl}
            closeBookingAt={closeBookingAt}
            recordingAvailable={recordingAvailable}
          />
        </section>
      </article>

      {(program || location || speakerList) && (
        <SelectorProgramLocation
          program={program}
          location={location}
          speakerList={speakerList}
        />
      )}

      {relevantCourses && relevantCourses.length > 0 && (
        <div>
          <div className="o-wrapper u-padding-top-bottom">
            <ArrangementDatoType />
            {relevantCourses.map(entry => (
              <RelevantCourseLink key={entry.title} entry={entry} />
            ))}
          </div>
        </div>
      )}
      <Footer contact={contact} settings={settings} />
    </>
  );
};

export const getStaticProps: GetStaticProps<PreviewRequirements> = async ({
  params,
}) => {
  const { slug = "" } = params;
  const groqParams = { slug };
  const groqQuery = {
    //portableTextQuery is needed both in location and root-level at the document
    doc: `*[_type == "conference" && slug.current == $slug && !(_id in path("drafts.*"))][0]
    {
      ...,

      location{title, description, locationPoint,
      ${portableTextQuery}},
      ${portableTextQuery},
      keywords,
      "relevantCourses": relevantCourses[]->,
      "coursePerson": coursePerson->
      {
        title,
        email,
        phoneNumber,
        workPosition,
        image,
        keywords,
      },
      "adminPerson": adminPerson->
      {
        title,
        email,
        phoneNumber,
        workPosition,
        image,
        keywords,
      },
      "speakerList": speakerList[]{..., speakerRef->}
    }
    `,
    ...globalQueries,
  };

  const result = await client.fetch(groqQuery, groqParams);

  if (!result || !result.doc || Object.keys(result.doc).length === 0) {
    return { notFound: true, props: { groqQuery, groqParams } };
  }

  return { props: { ...result, groqQuery, groqParams } };
};

export async function getStaticPaths() {
  const docs = await client.fetch(
    `*[_type == "conference" && !(_id in path('drafts.**'))][0...10000]{slug}`
  );
  const paths = docs.map(doc => ({ params: { slug: doc.slug.current } }));
  return { paths, fallback: "blocking" };
}

export default ConferencePage;
