import Image from "../media/image";
import PropTypes from "prop-types";
import PortableText from "components/portableText/portableText";

const SpeakerList = ({ speakerList }) => {
  return (
    <section className="c-speaker-list">
      <div className="o-wrapper">
        <h2 className="u-hide--desktop c-speaker-list__title-mobile">
          Foredragsholdere
        </h2>
        {speakerList.map((speaker, index) => (
          <Speaker
            speaker={speaker.speakerRef}
            overridePortableText={speaker.speakerOverridePortable}
            key={index}
          />
        ))}
      </div>
    </section>
  );
};

const Speaker = ({ speaker, introduction, overridePortableText }) => {
  const { image, title, workPosition, lede, limitedPortableText } = speaker;
  return (
    <div className="c-speaker">
      {image && (
        <div className="c-speaker__image">
          <Image
            alt={`Bilde av ${title}`}
            image={image}
            interceptBuilder={builder => builder.width(180).height(180)}
          />
        </div>
      )}
      <div className="c-speaker__content">
        {title && <h3 className="c-speaker__title">{title}</h3>}
        {workPosition && <p className="c-speaker__position">{workPosition}</p>}

        {(overridePortableText || limitedPortableText) && (
          <p className="c-speaker__introduction">
            <PortableText
              blocks={overridePortableText || limitedPortableText}
            />
          </p>
        )}
      </div>
    </div>
  );
};

SpeakerList.propTypes = {
  speakerList: PropTypes.array,
};

Speaker.propTypes = {
  speaker: PropTypes.object,
  introduction: PropTypes.string,
  portableText: PropTypes.array,
};

export default SpeakerList;
