import Location from "components/course/location";
import Program from "components/course/program";
import SpeakerList from "components/course/speaker.list";
import { useState } from "react";
import PropTypes from "prop-types";

const SelectorProgramLocation = ({ program, location, speakerList }) => {
  const [tab, setTab] = useState(program ? 0 : location ? 2 : 1);
  return (
    <section className="c-selector">
      <div className="o-wrapper c-selector__flex">
        {program && (
          <button
            className={
              tab == 0
                ? "c-selector__item u-bg--light-yellow"
                : "c-selector__item"
            }
            onClick={() => setTab(0)}
          >
            Program
          </button>
        )}
        {speakerList && speakerList.length > 0 && (
          <button
            className={
              tab == 1
                ? "c-selector__item u-bg--light-yellow"
                : "c-selector__item"
            }
            onClick={() => setTab(1)}
          >
            Foredragsholdere
          </button>
        )}
        {location && (
          <button
            className={
              tab == 2
                ? "c-selector__item u-bg--light-yellow"
                : "c-selector__item"
            }
            onClick={() => setTab(2)}
          >
            Praktisk informasjon
          </button>
        )}
      </div>
      {program && (
        <div className={tab == 0 ? "" : "c-selector--hide-component"}>
          <Program program={program} />
        </div>
      )}

      {speakerList && speakerList.length > 0 && (
        <div className={tab == 1 ? "" : "c-selector--hide-component"}>
          <SpeakerList speakerList={speakerList} />
        </div>
      )}
      {location && (
        <div className={tab == 2 ? "" : "c-selector--hide-component"}>
          {location && <Location location={location} />}
        </div>
      )}
    </section>
  );
};

SelectorProgramLocation.propTypes = {
  program: PropTypes.array,
  location: PropTypes.object,
  speakerList: PropTypes.array,
  startTab: PropTypes.bool,
};

export default SelectorProgramLocation;
