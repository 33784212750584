import RenLink from "components/shared/RenLink";
import PropTypes from "prop-types";
import CardEmployee from "components/employee/card.employee";
import ArrowIcon from "../icons/arrow.icon";
import { prettyDate } from "helpers/dates";
import { isAfter, parseISO } from "date-fns";
import { eventHasEntranceFee } from "./courseUtils";

type SignUpButtonProps = {
  url: string;
  isItAfter: boolean;
  recordingAvailable: boolean;
  eventIsNotFree: boolean;
};

const SignUpButton = ({
  url,
  isItAfter,
  recordingAvailable,
  eventIsNotFree,
}: SignUpButtonProps) => {
  if (url && !isItAfter) {
    return (
      <RenLink classes="c-event__sign-up" href={url}>
        <div className="c-event__sign-up--flex">
          <h3>Påmelding</h3>
          <div className="o-grid o-grid__center">
            <ArrowIcon />
          </div>
        </div>
      </RenLink>
    );
  }

  if (recordingAvailable && eventIsNotFree) {
    return (
      <div className="c-event__sign-up">
        <div className="c-event__sign-up--flex u-bg--light-yellow">
          <h3>
            Interessert? Du kan kjøpe tilgang til opptaket, send en e-post til{" "}
            <a href={`mailto:arrangement@ren.no`}>arrangement@ren.no</a>
          </h3>
        </div>
      </div>
    );
  }

  return null;
};

const CourseInfo = ({
  coursePerson,
  adminPerson,
  courseDate,
  endDate,
  dateOverride,
  duration,
  price,
  categories,
  courseLocation,
  suitableFor,
  signUpUrl,
  closeBookingAt,
  recordingAvailable,
}) => {
  const now = new Date();
  const nowISO = now.toISOString();

  const isItAfter = isAfter(
    parseISO(nowISO),
    parseISO(closeBookingAt ? closeBookingAt : courseDate)
  );

  const eventIsNotFree = eventHasEntranceFee(price);

  return (
    <aside className="c-event-info u-text--black">
      <div className="c-event-info__main">
        {courseDate && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Dato</p>
            <p className="u-margin--rm">
              {dateOverride && dateOverride.trim() && (
                <span>{dateOverride}</span>
              )}
              {(!dateOverride || (dateOverride && !dateOverride.trim())) && (
                <time dateTime={`${courseDate}`}>
                  {prettyDate({ date: courseDate, dateFormat: "dd.MM.yyyy" })}
                  {endDate &&
                    " til " +
                      prettyDate({ date: endDate, dateFormat: "dd.MM.yyyy" })}
                </time>
              )}
            </p>
          </div>
        )}
        {courseDate && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Varighet</p>
            <p className="u-margin--rm">
              {duration && duration.trim() && <span>{duration}</span>}
              {(!duration || (duration && !duration.trim())) && (
                <time dateTime={`${courseDate}`}>
                  {"kl " +
                    prettyDate({
                      date: courseDate,
                      dateFormat: "HH:mm",
                      modifyFormatInTimeZone: true,
                    })}
                  {endDate &&
                    " til kl " +
                      prettyDate({
                        date: endDate,
                        dateFormat: "HH:mm",
                        modifyFormatInTimeZone: true,
                      })}
                </time>
              )}
            </p>
          </div>
        )}

        {courseLocation && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Sted</p>
            <p className="u-margin--rm">{courseLocation}</p>
          </div>
        )}

        {suitableFor && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Passer for</p>
            <p className="u-margin--rm">{suitableFor}</p>
          </div>
        )}

        {categories && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Fagområder</p>
            <div className="">
              {categories.map(category => (
                <p key={`${category}`} className="u-margin--rm">
                  {category}
                </p>
              ))}
            </div>
          </div>
        )}

        {price && (
          <div className="c-event-info__main--margin-bottom">
            <p className="u-margin--rm c-event-info--bold">Pris</p>
            <p className="u-margin--rm"> {price} </p>
          </div>
        )}
      </div>

      <SignUpButton
        url={signUpUrl}
        isItAfter={isItAfter}
        recordingAvailable={recordingAvailable}
        eventIsNotFree={eventIsNotFree}
      />

      {adminPerson && (
        <div className="c-event-info__card-employee">
          <CardEmployee
            coursePerson={adminPerson}
            conferenceRole="Arrangementsansvarlig"
            smallVersion
            lightCard
          />
        </div>
      )}

      {coursePerson && (
        <div className="c-event-info__card-employee">
          <CardEmployee
            coursePerson={coursePerson}
            conferenceRole="Fagansvarlig"
            smallVersion
          />
        </div>
      )}
    </aside>
  );
};

CourseInfo.propTypes = {
  coursePerson: PropTypes.object,
  courseDate: PropTypes.string,
  price: PropTypes.string,
  categories: PropTypes.array,
};

export default CourseInfo;
