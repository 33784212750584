import PropTypes from "prop-types";
import { useState } from "react";
import { prettyTime } from "../../helpers/dates";
import ExpandIcon from "../icons/expand.icon";

const shouldHeadersBeBold = isHeading => {
  if (isHeading) {
    return "c-program-day--event__text-title u-text--bold";
  } else {
    return "c-program-day--event__text-title";
  }
};

const ProgramDay = ({ programDay, startOpen = false }) => {
  const [value, setValue] = useState(startOpen);
  return (
    <div className="c-program-day">
      <div>
        <button
          className="c-program-day--title c-program-day__button"
          onClick={() => setValue(!value)}
        >
          <h2 className="c-program-day--title">{programDay.title}</h2>
          <div
            className={
              value
                ? "c-program-day--title c-program-day__button-icon"
                : "c-program-day--title c-program-day__button-icon c-program-day__button-icon--collapsed"
            }
          >
            <ExpandIcon />
          </div>
        </button>

        {/*  */}
      </div>
      {value &&
        programDay &&
        programDay.programPoints &&
        programDay.programPoints.map(programPoint => (
          <div className="c-program-day--wrapper" key={programPoint._key}>
            <div className="c-program-day--time">
              <p className="">
                {programPoint.time
                  ? prettyTime(programPoint.time)
                  : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
              </p>
            </div>
            <div className="c-program-day--event__text">
              <p className={shouldHeadersBeBold(programPoint.heading)}>
                {programPoint.title}
              </p>
              <p>{programPoint.description}</p>
              {programPoint.speaker ? <p> {programPoint.speaker}</p> : <></>}
            </div>
          </div>
        ))}
    </div>
  );
};

ProgramDay.propTypes = {
  programDay: PropTypes.object,
  startOpen: PropTypes.bool,
};

export default ProgramDay;
